"use client";

import { useTheme } from "@mui/material";
import clsx from "clsx";

export default function CenterContainerLayout({
  children,
  flat = false,
}: {
  children: React.ReactNode;
  flat?: boolean;
}) {
  const theme = useTheme();
  return (
    <div className="box-border flex h-screen max-h-screen w-fit flex-col items-start justify-start gap-2.5 overflow-hidden">
      <main
        className=" box-border flex h-screen max-h-screen min-h-screen w-screen flex-shrink-0 flex-grow-0 items-center justify-center overflow-auto p-2"
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <div
          className={clsx([
            "min-w-full sm:min-w-[400px]",
            flat
              ? "p-0"
              : "border-1 rounded-md  border-solid border-gray-200 bg-white p-4 p-4 shadow-md",
          ])}
        >
          {children}
        </div>
      </main>
    </div>
  );
}
